import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { CustomerComponent } from './customer/customer.component';
import { InvoiceInputComponent } from './invoice-input/invoice-input.component';
import { InvoiceOutputComponent } from './invoice-output/invoice-output.component';
import { ProductComponent } from './product/product.component';
import { ProductWarehouseComponent } from './product-warehouse/product-warehouse.component';
import { SupplierComponent } from './supplier/supplier.component';
import { InvoiceOutputDetailHistoryComponent } from './invoice-output/invoice-output-detail-history/invoice-output-detail-history.component';
import { InvoiceInputDetailHistoryComponent } from './invoice-input/invoice-input-detail-history/invoice-input-detail-history.component';
import { MembershipComponent } from './membership/membership.component';
import { InvoiceCostComponent } from './invoice-cost/invoice-cost.component';
import { InvoiceCostDetailHistoryComponent } from './invoice-cost/invoice-cost-detail-history/invoice-cost-detail-history.component';
import { InvoiceSalaryComponent } from './invoice-salary/invoice-salary.component';
import { InvoiceSalaryDetailHistoryComponent } from './invoice-salary/invoice-salary-detail-history/invoice-salary-detail-history.component';
import { ReportComponent } from './report/report.component';

const routes: Routes = [  
  { path: '', redirectTo: '/Homepage', pathMatch: 'full' },
  {
    path: 'Homepage', component: InvoiceOutputDetailHistoryComponent,
  },   
  {
    path: 'Report', component: ReportComponent,
  }, 
  {
    path: 'InvoiceInput', component: InvoiceInputComponent,
  },  
  {
    path: 'InvoiceInputDetailHistory', component: InvoiceInputDetailHistoryComponent,
  }, 
  {
    path: 'InvoiceOutput', component: InvoiceOutputComponent,
  },  
  {
    path: 'InvoiceOutputDetailHistory', component: InvoiceOutputDetailHistoryComponent,
  },   
  {
    path: 'InvoiceCost', component: InvoiceCostComponent,
  },  
  {
    path: 'InvoiceCostDetailHistory', component: InvoiceCostDetailHistoryComponent,
  }, 
  {
    path: 'InvoiceSalary', component: InvoiceSalaryComponent,
  },  
  {
    path: 'InvoiceSalaryDetailHistory', component: InvoiceSalaryDetailHistoryComponent,
  }, 
  {
    path: 'Product', component: ProductComponent,
  },  
  {
    path: 'ProductWarehouse', component: ProductWarehouseComponent,
  },  
  {
    path: 'Supplier', component: SupplierComponent,
  },  
  {
    path: 'Customer', component: CustomerComponent,
  },  
  {
    path: 'Membership', component: MembershipComponent,
  },  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
