import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/notification.service';
import { InvoiceCost } from 'src/app/shared/InvoiceCost.model';
import { InvoiceCostService } from 'src/app/shared/InvoiceCost.service';
import { InvoiceCostDetail } from 'src/app/shared/InvoiceCostDetail.model';
import { InvoiceCostDetailService } from 'src/app/shared/InvoiceCostDetail.service';
import { Membership } from 'src/app/shared/Membership.model';
import { MembershipService } from 'src/app/shared/Membership.service';
import { Product } from 'src/app/shared/Product.model';
import { ProductService } from 'src/app/shared/Product.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DownloadService } from 'src/app/shared/Download.service';

@Component({
  selector: 'app-invoice-cost-detail-history',
  templateUrl: './invoice-cost-detail-history.component.html',
  styleUrls: ['./invoice-cost-detail-history.component.css']
})
export class InvoiceCostDetailHistoryComponent implements OnInit {

  ID: number = environment.InitializationNumber;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  dateTimeBegin: Date = new Date();
  dateTimeEnd: Date = new Date();
  total: number = environment.InitializationNumber;
  MembershipID: number = environment.InitializationNumber;
  dataSource: MatTableDataSource<any>;
  displayColumns: string[] = ['DateCreated', 'Note', 'Quantity', 'Price', 'Total', 'Save'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public InvoiceCostService: InvoiceCostService,
    public InvoiceCostDetailService: InvoiceCostDetailService,
    public MembershipService: MembershipService,
    public ProductService: ProductService,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
  ) {

  }
  ngOnInit(): void {    
    this.GetToList();
  }
  onChangeDateTimeBegin(value) {
    this.dateTimeBegin = new Date(value);
  }
  onChangeDateTimeEnd(value) {
    this.dateTimeEnd = new Date(value);
  }
  onSearch() {    
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetToList();
    }
  }
 
  GetToList() {
    this.isShowLoading = true;
    this.InvoiceCostDetailService.GetByDateTimeBeginAndDateTimeEndAndSearchStringToListAsync(this.dateTimeBegin, this.dateTimeEnd, this.searchString).subscribe(
      res => {
        this.InvoiceCostDetailService.list = res as InvoiceCost[];
        if (this.InvoiceCostDetailService.list) {
          this.dataSource = new MatTableDataSource(this.InvoiceCostDetailService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.total = environment.InitializationNumber;
          for (let i = 0; i < this.InvoiceCostDetailService.list.length; i++) {
            this.total = this.total + this.InvoiceCostDetailService.list[i].Total;
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onChangeDateCreated(element: InvoiceCostDetail, value) {
    element.DateCreated = new Date(value);
  }
  onSave(element: InvoiceCostDetail) {
    this.InvoiceCostDetailService.SaveAsync(element).subscribe(
      res => {
        this.GetToList();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onDelete(element: InvoiceCostDetail) {
    if (confirm(environment.DeleteConfirm)) {
      this.InvoiceCostDetailService.RemoveAsync(element.ID).subscribe(
        res => {
          this.GetToList();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  onSyncByDateTimeBeginAndDateTimeEndAsync() {
    this.isShowLoading = true;
    this.InvoiceCostService.SyncByDateTimeBeginAndDateTimeEndAsync(this.dateTimeBegin, this.dateTimeEnd).subscribe(
      res => {
        this.NotificationService.warn(environment.SaveSuccess);
        this.onSearch();
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
}
