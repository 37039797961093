import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/notification.service';
import { InvoiceInput } from 'src/app/shared/InvoiceInput.model';
import { InvoiceInputService } from 'src/app/shared/InvoiceInput.service';
import { InvoiceInputDetail } from 'src/app/shared/InvoiceInputDetail.model';
import { InvoiceInputDetailService } from 'src/app/shared/InvoiceInputDetail.service';
import { Supplier } from 'src/app/shared/Supplier.model';
import { SupplierService } from 'src/app/shared/Supplier.service';
import { Product } from 'src/app/shared/Product.model';
import { ProductService } from 'src/app/shared/Product.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DownloadService } from 'src/app/shared/Download.service';

@Component({
  selector: 'app-invoice-input-detail',
  templateUrl: './invoice-input-detail.component.html',
  styleUrls: ['./invoice-input-detail.component.css']
})
export class InvoiceInputDetailComponent implements OnInit {

  ID: number = environment.InitializationNumber;
  isShowLoading: boolean = false;

  dataSource: MatTableDataSource<any>;
  displayColumns: string[] = ['ProductID', 'Quantity', 'Price', 'Total', 'Save'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public InvoiceInputService: InvoiceInputService,
    public InvoiceInputDetailService: InvoiceInputDetailService,
    public SupplierService: SupplierService,
    public ProductService: ProductService,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    public dialogRef: MatDialogRef<InvoiceInputDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.ID = data["ID"] as number;
  }
  ngOnInit(): void {    
    this.GetSupplierToListAsync();
    this.GetProductToListAsync();    
    this.GetByParentIDAndEmptyToListAsync();
  }
  GetSupplierToListAsync() {
    this.SupplierService.GetByActiveToListAsync(true).subscribe(
      res => {
        this.SupplierService.list = (res as Supplier[]).sort((a, b) => (a.Display > b.Display ? 1 : -1));
        this.SupplierService.listSearch = this.SupplierService.list;
        if (this.SupplierService.listSearch) {
          if (this.SupplierService.listSearch.length > 0) {
            if (this.InvoiceInputService.formData.ID == 0) {
              this.InvoiceInputService.formData.SupplierID = this.SupplierService.listSearch[0].ID;
            }
          }
        }
      },
      err => {
      }
    );
  }
  GetProductToListAsync() {
    this.ProductService.GetByActiveToListAsync(true).subscribe(
      res => {
        this.ProductService.list = (res as Product[]).sort((a, b) => (a.Display > b.Display ? 1 : -1));
        // if (this.ProductService.list) {
        //   if (this.ProductService.list.length > 0) {
        //     if (this.InvoiceInputDetailService.list) {
        //       if (this.InvoiceInputDetailService.list.length > 0) {
        //         this.InvoiceInputDetailService.list[0].ProductID = this.ProductService.list[0].ID;
        //       }
        //     }
        //   }
        // }
      },
      err => {
      }
    );
  }
  onClose() {
    this.dialogRef.close();
  }

  onSubmit(form: NgForm) {
    this.InvoiceInputService.SaveAsync(form.value).subscribe(
      res => {
        this.InvoiceInputService.formData = res as InvoiceInput;
        this.GetByIDAsync();
        this.NotificationService.success(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onChangeDateCreated(value) {
    this.InvoiceInputService.formData.DateCreated = new Date(value);
  }
  GetByIDAsync() {
    this.InvoiceInputService.GetByIDAsync(this.InvoiceInputService.formData.ID).subscribe(
      res => {
        this.InvoiceInputService.formData = res as InvoiceInput;     
        this.GetByParentIDAndEmptyToListAsync();  
      },
      err => {
      }
    );
  }
  GetByParentIDAndEmptyToListAsync() {
    this.isShowLoading = true;
    this.InvoiceInputDetailService.GetByParentIDAndEmptyToListAsync(this.InvoiceInputService.formData.ID).subscribe(
      res => {
        this.InvoiceInputDetailService.list = res as InvoiceInputDetail[];
        this.dataSource = new MatTableDataSource(this.InvoiceInputDetailService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
        if (this.InvoiceInputDetailService.list) {
          if (this.InvoiceInputDetailService.list.length > 0) {
            //this.GetProductToList();
          }
        }
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSave(element: InvoiceInputDetail) {
    element.ParentID = this.InvoiceInputService.formData.ID;
    this.InvoiceInputDetailService.SaveAsync(element).subscribe(
      res => {
        this.GetByIDAsync();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onDelete(element: InvoiceInputDetail) {
    if (confirm(environment.DeleteConfirm)) {
      this.InvoiceInputDetailService.RemoveAsync(element.ID).subscribe(
        res => {
          this.GetByIDAsync();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  onPrint() {
    this.isShowLoading = true;
    this.DownloadService.InvoiceInputByIDToHTML(this.InvoiceInputService.formData.ID).then(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      }
    );
  }
  onFilterSupplier(searchString: string) {
    if (searchString.length > 0) {
      this.SupplierService.listSearch = this.SupplierService.list.filter(item => item.Display && item.Display.length > 0 && item.Display.toLocaleLowerCase().includes(searchString));      
    }
    else {
      this.SupplierService.listSearch = this.SupplierService.list;
    }
  }
}
