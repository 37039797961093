<div class="row">
    <div id="breadcrumbs-wrapper" data-image="https://anhtruc.sohu.vn/app-assets/images/gallery/breadcrumb-bg.jpg"
        style="color: #ffffff; background: rgb(40,70,99); background: linear-gradient(90deg, rgba(40,70,99,1) 0%, rgba(62,110,159,1) 35%, rgba(106,179,245,1) 100%);">
        <div class="container">
            <div class="row">
                <div class="col s12 m6 l6">
                    <h5 class="breadcrumbs-title mt-0 mb-0" style="color: #ffffff;">Báo cáo thu chi <span
                            *ngIf="ReportService.list">({{ReportService.list.length}} items)</span>
                    </h5>
                </div>
                <div class="col s12 m6 l6 right-align-md">
                    <ol class="breadcrumbs mb-0">
                        <li class="breadcrumb-item"><a href="#" style="color: #ffffff;">CRM</a>
                        </li>
                        <li class="breadcrumb-item active" style="color: #ffffff;">Báo cáo thu chi
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col s12">
        <div class="container">
            <div class="section section-data-tables">
                <div class="card">
                    <div id="1">
                        <div class="card-content">
                            <div class="col s12 m12 l2">
                                <input matInput [ngModel]="dateTimeBegin | date:'yyyy-MM-dd'"
                                    (ngModelChange)="onChangeDateTimeBegin($event)"
                                    [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                    class="mat-input-element mat-form-field-autofill-control" autocomplete="off">
                            </div>
                            <div class="col s12 m12 l2">
                                <input matInput [ngModel]="dateTimeEnd | date:'yyyy-MM-dd'"
                                    (ngModelChange)="onChangeDateTimeEnd($event)"
                                    [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                    class="mat-input-element mat-form-field-autofill-control" autocomplete="off">
                            </div>
                            <div class="col s12 m12 l2">
                                <a title="Tìm" (click)="onSearch()"
                                    class="btn-floating waves-effect waves-light green darken-1"><i
                                        class="material-icons">search</i></a>&nbsp;&nbsp;&nbsp;
                            </div>
                            <div class="KhungOverflow">
                                <mat-table [dataSource]="dataSource" matSort>
                                    <ng-container matColumnDef="DateTimeBegin">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Bắt đầu
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element">{{element.DateTimeBegin |
                                            date:'yyyy-MM-dd'}}</mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="DateTimeEnd">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Kết thúc
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element">{{element.DateTimeEnd |
                                            date:'yyyy-MM-dd'}}</mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="InvoiceOutput">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Bán hàng
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <input disabled style="color: green; text-align: right; font-weight: bold;" value="{{element.InvoiceOutput | number: '1.0-0'}}">
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="InvoiceInput">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Mua hàng
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <input disabled style="color: red; text-align: right; font-weight: bold;" value="{{element.InvoiceInput | number: '1.0-0'}}">
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="InvoiceCost">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Chi phí
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <input disabled style="color: red; text-align: right; font-weight: bold;" value="{{element.InvoiceCost | number: '1.0-0'}}">
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="InvoiceSalary">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Lương + ứng
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <input disabled style="color: red; text-align: right; font-weight: bold;" value="{{element.InvoiceSalary | number: '1.0-0'}}">
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="Excess">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Còn lại
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element">                                           
                                                <input *ngIf="element.Excess>=0" disabled style="color: green; text-align: right; font-weight: bold;" value="{{element.Excess | number: '1.0-0'}}">
                                                <input *ngIf="element.Excess<0" disabled style="color: red; text-align: right; font-weight: bold;" value="{{element.Excess | number: '1.0-0'}}">                                            
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="loading">
                                        <mat-footer-cell *matFooterCellDef colspan="4">
                                            Đang tải dữ liệu...
                                        </mat-footer-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="noData">
                                        <mat-footer-cell *matFooterCellDef colspan="4">
                                            Chưa có dữ liệu.
                                        </mat-footer-cell>
                                    </ng-container>
                                    <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
                                    <mat-footer-row *matFooterRowDef="['loading']"
                                        [ngClass]="{'hide':dataSource!=null}"></mat-footer-row>
                                    <mat-footer-row *matFooterRowDef="['noData']"
                                        [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length==0)}">
                                    </mat-footer-row>
                                </mat-table>
                                <!-- <mat-paginator [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                    [showFirstLastButtons]></mat-paginator>                            -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>