import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Report } from 'src/app/shared/Report.model';
@Injectable({
    providedIn: 'root'
})
export class ReportService {
    list: Report[] | undefined;
    formData!: Report;
    aPIURL: string = environment.APIURL;
    controller: string = "Report";
    constructor(private httpClient: HttpClient) {
        this.initializationFormData();
    }
    initializationFormData() {
        this.formData = {
        }
    }    
    GetByDateTimeBeginAndDateTimeEndToListAsync(dateTimeBegin: Date, dateTimeEnd: Date) {
        let url = this.aPIURL + this.controller + '/GetByDateTimeBeginAndDateTimeEndToListAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('dateTimeBegin', JSON.stringify(dateTimeBegin));
        formUpload.append('dateTimeEnd', JSON.stringify(dateTimeEnd));        
        return this.httpClient.post(url, formUpload);
    }    
}

