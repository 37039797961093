import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/notification.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ProductWarehouse } from 'src/app/shared/ProductWarehouse.model';
import { ProductWarehouseService } from 'src/app/shared/ProductWarehouse.service';

@Component({
  selector: 'app-product-warehouse',
  templateUrl: './product-warehouse.component.html',
  styleUrls: ['./product-warehouse.component.css']
})
export class ProductWarehouseComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  displayColumns: string[] = ['DateCreated', 'Name', 'QuantityInput', 'QuantityOutput', 'QuantityInventory'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  dateTime: Date = new Date();
  id: any;
  constructor(
    public ProductWarehouseService: ProductWarehouseService,
    public NotificationService: NotificationService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.onSearch();
    this.id = setInterval(() => {
      this.onSearch();
    }, 60000);
  }
  onChangeDateTime(value) {
    this.dateTime = new Date(value);
  }
  getToList() {
    this.isShowLoading = true;
    this.ProductWarehouseService.GetByDateTimeToListAsync(this.dateTime).subscribe(
      res => {
        this.ProductWarehouseService.list = res as ProductWarehouse[];
        this.dataSource = new MatTableDataSource(this.ProductWarehouseService.list.sort((a, b) => (a.DateCreated > b.DateCreated ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.getToList();
    }
  }
}
