import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/notification.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Report } from 'src/app/shared/Report.model';
import { ReportService } from 'src/app/shared/Report.service';
import { Supplier } from 'src/app/shared/Supplier.model';
import { SupplierService } from 'src/app/shared/Supplier.service';
import { DownloadService } from 'src/app/shared/Download.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  displayColumns: string[] = ['DateTimeBegin', 'DateTimeEnd', 'InvoiceOutput', 'InvoiceInput', 'InvoiceCost', 'InvoiceSalary', 'Excess'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  dateTimeBegin: Date = new Date();
  dateTimeEnd: Date = new Date();
  total: number = environment.InitializationNumber;
  supplierID: number = environment.InitializationNumber;
  id: any;
  constructor(
    public ReportService: ReportService,
    public SupplierService: SupplierService,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {    
    this.onSearch();
  }
  onChangeDateTimeBegin(value) {
    this.dateTimeBegin = new Date(value);
  }
  onChangeDateTimeEnd(value) {
    this.dateTimeEnd = new Date(value);
  }  
  GetToList() {
    this.isShowLoading = true;
    this.ReportService.GetByDateTimeBeginAndDateTimeEndToListAsync(this.dateTimeBegin, this.dateTimeEnd).subscribe(
      res => {
        this.ReportService.list = res as Report[];
        if (this.ReportService.list) {
          this.dataSource = new MatTableDataSource(this.ReportService.list);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.total = environment.InitializationNumber;          
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    this.GetToList();
  }  
}