<div class="row">
    <div id="breadcrumbs-wrapper" data-image="https://anhtruc.sohu.vn/app-assets/images/gallery/breadcrumb-bg.jpg"
        style="color: #ffffff; background: rgb(40,70,99); background: linear-gradient(90deg, rgba(40,70,99,1) 0%, rgba(62,110,159,1) 35%, rgba(106,179,245,1) 100%);">
        <div class="container">
            <div class="row">
                <div class="col s12 m6 l6">
                    <h5 class="breadcrumbs-title mt-0 mb-0" style="color: #ffffff;">Sổ nhập hàng <span
                            *ngIf="InvoiceInputDetailService.list">({{InvoiceInputDetailService.list.length - 1 }}
                            items)</span>
                    </h5>
                </div>
                <div class="col s12 m6 l6 right-align-md">
                    <ol class="breadcrumbs mb-0">
                        <li class="breadcrumb-item"><a href="#" style="color: #ffffff;">CRM</a>
                        </li>
                        <li class="breadcrumb-item active" style="color: #ffffff;">Sổ nhập hàng
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col s12">
        <div class="container">
            <div class="section section-data-tables">
                <div class="card">
                    <div id="1">
                        <div class="card-content">
                            <div class="col s12 m12 l2">
                                <input matInput [ngModel]="dateTimeBegin | date:'yyyy-MM-dd'"
                                    (ngModelChange)="onChangeDateTimeBegin($event)"
                                    [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                    class="mat-input-element mat-form-field-autofill-control" autocomplete="off">
                            </div>
                            <div class="col s12 m12 l2">
                                <input matInput [ngModel]="dateTimeEnd | date:'yyyy-MM-dd'"
                                    (ngModelChange)="onChangeDateTimeEnd($event)"
                                    [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                    class="mat-input-element mat-form-field-autofill-control" autocomplete="off">
                            </div>
                            <div class="col s12 m12 l2">
                                <mat-select
                                    style="padding-top:20px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                    name="supplierID" class="browser-default" [(ngModel)]="supplierID">
                                    <input (keyup)="onFilterSupplier001($event.target.value)">
                                    <mat-option *ngFor="let item of SupplierService.listSearch001" [value]="item.ID">
                                        {{item.Display}}
                                    </mat-option>
                                </mat-select>
                            </div>
                            <!-- <div class="col s12 m12 l2">  
                                <div class="input-field">
                                    <p>
                                        <label>
                                            <input type="checkbox" class="filled-in" name="isDateCreated"
                                                [(ngModel)]="isDateCreated" />
                                            <span>Ngày tạo/Tự động</span>
                                        </label>
                                    </p>
                                </div>                              
                            </div> -->
                            <div class="col s12 m12 l2">
                                <a title="Tìm" (click)="onSearch()"
                                    class="btn-floating waves-effect waves-light green darken-1"><i
                                        class="material-icons">search</i></a>&nbsp;&nbsp;&nbsp;
                                <a title="In đơn hàng" class="btn-floating waves-effect waves-light green darken-1"
                                    (click)="onPrintAll()"><i class="material-icons">print</i></a>&nbsp;&nbsp;&nbsp;
                                <a title="Đồng bộ" (click)="onSyncByDateTimeBeginAndDateTimeEndAsync()"
                                    class="btn-floating waves-effect waves-light cyan"><i
                                        class="material-icons">sync</i></a>
                            </div>
                            <div class="col s12 m12 l4">
                                <p style="font-weight: bold; color: red; padding-top: 10px; font-size: 30px;">Tổng:
                                    {{total |
                                    number:'1.0-0'}} đồng</p>
                            </div>
                            <div class="KhungOverflow">
                                <mat-table [dataSource]="dataSource" matSort>
                                    <!-- <ng-container matColumnDef="CreatedDate">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Tự động
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element">{{element.CreatedDate |
                                            date:'yyyy-MM-dd'}}</mat-cell>
                                    </ng-container> -->
                                    <ng-container matColumnDef="DateCreated">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Ngày tạo
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <input name="DateCreated{{element.ID}}" style="text-align: right;"
                                                [ngModel]="element.DateCreated | date:'yyyy-MM-dd'"
                                                (ngModelChange)="onChangeDateCreated(element, $event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date">
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="SupplierID">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Chủ ghe
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <mat-select *ngIf="element.ID==0"
                                                style="padding-top:10px; padding-bottom:6px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                name="SupplierID{{element.ID}}" class="browser-default"
                                                [(ngModel)]="element.SupplierID">
                                                <input (keyup)="onFilterSupplier($event.target.value)">
                                                <mat-option *ngFor="let item of SupplierService.listSearch"
                                                    [value]="item.ID">
                                                    {{item.Display}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-select *ngIf="element.ID>0"
                                                style="padding-top:10px; padding-bottom:6px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                name="SupplierID{{element.ID}}" class="browser-default"
                                                [(ngModel)]="element.SupplierID">
                                                <mat-option *ngFor="let item of SupplierService.list" [value]="item.ID">
                                                    {{item.Display}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="ProductID">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Sản phẩm
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <mat-select
                                                style="padding-top:10px; padding-bottom:6px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                name="ProductID{{element.ID}}" class="browser-default"
                                                [(ngModel)]="element.ProductID">
                                                <mat-option *ngFor="let item of ProductService.list" [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="Quantity">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Số lượng (kg)
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <input placeholder="0" name="Quantity{{element.ID}}"
                                                style="text-align: right;" [(ngModel)]="element.Quantity" type="number">
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="Price">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Giá (đồng)
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <input placeholder="0" name="Price{{element.ID}}" style="text-align: right;"
                                                [(ngModel)]="element.Price" type="number">
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="Note">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <input placeholder="Ghi chú" name="Note{{element.ID}}"
                                                [(ngModel)]="element.Note" type="text">
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="Total">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Tổng (đồng)
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <input disabled
                                                style="width: 100%; color: red; text-align: right; font-weight: bold;"
                                                value="{{element.Total | number: '1.0-0'}}">
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="Save">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            &nbsp;&nbsp;&nbsp;
                                            <a (click)="onSave(element)"
                                                class="btn-floating waves-effect waves-light cyan"><i
                                                    class="material-icons">save</i></a>&nbsp;&nbsp;&nbsp;
                                            <a title="Xóa" class="btn-floating waves-effect waves-light red"
                                                (click)="onDelete(element)"><i
                                                    class="material-icons">delete_outline</i></a>&nbsp;&nbsp;&nbsp;
                                            <a title="In đơn hàng"
                                                class="btn-floating waves-effect waves-light green darken-1"
                                                (click)="onPrint(element)"><i class="material-icons">print</i></a>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="loading">
                                        <mat-footer-cell *matFooterCellDef colspan="4">
                                            Đang tải dữ liệu...
                                        </mat-footer-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="noData">
                                        <mat-footer-cell *matFooterCellDef colspan="4">
                                            Chưa có dữ liệu.
                                        </mat-footer-cell>
                                    </ng-container>
                                    <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
                                    <mat-footer-row *matFooterRowDef="['loading']"
                                        [ngClass]="{'hide':dataSource!=null}"></mat-footer-row>
                                    <mat-footer-row *matFooterRowDef="['noData']"
                                        [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length==0)}">
                                    </mat-footer-row>
                                </mat-table>
                                <!-- <mat-paginator [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                    [showFirstLastButtons]></mat-paginator>                            -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>