<form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
    <div class="col s12 m12 l9">
        <h5 class="card-title"><b>Nhập hàng</b></h5>
    </div>
    <div class="col s12 m12 l3">
        <button title="Lưu thay đổi" type="submit" class="btn-floating waves-effect waves-light cyan"><i
                class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
        <a title="In đơn hàng" class="btn-floating waves-effect waves-light green darken-1" (click)="onPrint()"><i
                class="material-icons">print</i></a>&nbsp;&nbsp;&nbsp;
        <a title="Đóng" class="btn-floating waves-effect waves-light purple lightrn-1" (click)="onClose()">
            <i class="material-icons">close</i>
        </a>
    </div>
    <input name="ID" [(ngModel)]="InvoiceInputService.formData.ID" type="hidden">
    <input name="Name" [(ngModel)]="InvoiceInputService.formData.Name" type="hidden">
    <input name="TotalBeforeTax" [(ngModel)]="InvoiceInputService.formData.TotalBeforeTax" type="hidden">
    <div class="col s12 m12 l6">
        <div class="col s12 m12 l4">
            <div class="input-field">
                <label for="DateCreated">Ngày tạo</label>
            </div>
        </div>
        <div class="col s12 m12 l8">
            <div class="input-field">
                <input name="DateCreated" style="text-align: right; font-weight: bold; width: 100%;"
                    [ngModel]="InvoiceInputService.formData.DateCreated | date:'yyyy-MM-dd'"
                    (ngModelChange)="onChangeDateCreated($event)" [ngModelOptions]="{ updateOn: 'blur' }" type="date">
            </div>
        </div>
        <div class="col s12 m12 l4">
            <div class="input-field">
                <label for="SupplierID">Nhà cung cấp</label>
            </div>
        </div>
        <div class="col s12 m12 l8">
            <div class="input-field">
                <mat-select
                    style="padding-top:20px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                    name="SupplierID" class="browser-default" [(ngModel)]="InvoiceInputService.formData.SupplierID">
                    <input (keyup)="onFilterSupplier($event.target.value)">             
                    <mat-option *ngFor="let item of SupplierService.listSearch" [value]="item.ID">
                        {{item.Display}}
                    </mat-option>
                </mat-select>
            </div>
        </div>
    </div>
    <div class="col s12 m12 l6">
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Code">Mã số (Tự động)</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input disabled placeholder="Mã số (Tự động)" name="Code"
                    [(ngModel)]="InvoiceInputService.formData.Code" type="number">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="TotalBeforeTax">Thành tiền</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <div name="TotalBeforeTax" style="text-align: right; color: red; font-weight: bold; font-size: 20px;">
                    {{InvoiceInputService.formData.TotalBeforeTax | number:
                    '1.0-0'}}
                </div>
            </div>
        </div>
    </div>
    <div class="col s12 m12 l12">
        <div class="KhungOverflow">
            <mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="ProductID">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Sản phẩm
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                        <mat-select
                            style="padding-top:10px; padding-bottom:6px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                            name="ProductID{{element.ID}}" class="browser-default" [(ngModel)]="element.ProductID">
                            <mat-option *ngFor="let item of ProductService.list" [value]="item.ID">
                                {{item.Name}}
                            </mat-option>
                        </mat-select>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Quantity">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Số lượng (kg)
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                        <input placeholder="0" name="Quantity{{element.ID}}" style="text-align: right;"
                            [(ngModel)]="element.Quantity" type="number">
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Price">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Giá (đồng)
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                        <input placeholder="0" name="Price{{element.ID}}" style="text-align: right;"
                            [(ngModel)]="element.Price" type="number">
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Total">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Tổng (đồng)
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="width: 100%; text-align: right; font-weight: bolder;">
                        {{element.Total | number: '1.0-0'}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Save">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        &nbsp;&nbsp;&nbsp;
                        <a (click)="onSave(element)" class="btn-floating waves-effect waves-light cyan"><i
                                class="material-icons">save</i></a>&nbsp;&nbsp;&nbsp;
                        <a title="Xóa" class="btn-floating waves-effect waves-light red" (click)="onDelete(element)"><i
                                class="material-icons">delete_outline</i></a>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="loading">
                    <mat-footer-cell *matFooterCellDef colspan="4">
                        Đang tải dữ liệu...
                    </mat-footer-cell>
                </ng-container>
                <ng-container matColumnDef="noData">
                    <mat-footer-cell *matFooterCellDef colspan="4">
                        Chưa có dữ liệu.
                    </mat-footer-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
                <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':dataSource!=null}"></mat-footer-row>
                <mat-footer-row *matFooterRowDef="['noData']"
                    [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length==0)}">
                </mat-footer-row>
            </mat-table>
            <!-- <mat-paginator [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                [showFirstLastButtons]></mat-paginator>                            -->
        </div>
    </div>
</form>
<app-loading *ngIf="isShowLoading"></app-loading>