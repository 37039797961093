import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/notification.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { InvoiceInput } from 'src/app/shared/InvoiceInput.model';
import { InvoiceInputService } from 'src/app/shared/InvoiceInput.service';
import { InvoiceInputDetailComponent } from './invoice-input-detail/invoice-input-detail.component';
import { Supplier } from 'src/app/shared/Supplier.model';
import { SupplierService } from 'src/app/shared/Supplier.service';
import { DownloadService } from 'src/app/shared/Download.service';

@Component({
  selector: 'app-invoice-input',
  templateUrl: './invoice-input.component.html',
  styleUrls: ['./invoice-input.component.css']
})
export class InvoiceInputComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  displayColumns: string[] = ['DateCreated', 'Code', 'Name', 'TotalBeforeTax', 'Save'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  dateTimeBegin: Date = new Date();
  dateTimeEnd: Date = new Date();
  total: number = environment.InitializationNumber;
  supplierID: number = environment.InitializationNumber;
  id: any;
  constructor(
    public InvoiceInputService: InvoiceInputService,
    public SupplierService: SupplierService,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.GetSupplierToListAsync();
    this.onSearch();    
  }
  onChangeDateTimeBegin(value) {
    this.dateTimeBegin = new Date(value);
  }
  onChangeDateTimeEnd(value) {
    this.dateTimeEnd = new Date(value);
  }
  GetSupplierToListAsync() {
    this.SupplierService.GetAllAndEmptyToListAsync().subscribe(
      res => {
        this.SupplierService.list = (res as Supplier[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));   
        this.SupplierService.listSearch = this.SupplierService.list;     
      },
      err => {
      }
    );
  }
  GetToList() {
    this.isShowLoading = true;
    this.InvoiceInputService.GetBySupplierIDAndDateTimeBeginAndDateTimeEndToListAsync(this.supplierID, this.dateTimeBegin, this.dateTimeEnd).subscribe(
      res => {
        this.InvoiceInputService.list = res as InvoiceInput[];
        if (this.InvoiceInputService.list) {
          this.dataSource = new MatTableDataSource(this.InvoiceInputService.list.sort((a, b) => (a.DateCreated > b.DateCreated ? 1 : -1)));
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.total = environment.InitializationNumber;
          for (let i = 0; i < this.InvoiceInputService.list.length; i++) {
            this.total = this.total + this.InvoiceInputService.list[i].TotalBeforeTax;
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetToList();
    }
  }
  onAdd(ID: any) {
    this.InvoiceInputService.GetByIDAsync(ID).subscribe(
      res => {
        this.InvoiceInputService.formData = res as InvoiceInput;
        console.log(this.InvoiceInputService.formData);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(InvoiceInputDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.GetToList();
        });
      },
      err => {
      }
    );
  }
  onDelete(element: InvoiceInput) {
    if (confirm(environment.DeleteConfirm)) {
      this.InvoiceInputService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
        }
      );
    }
  }
  onPrint(element: InvoiceInput) {
    this.isShowLoading = true;
    this.DownloadService.InvoiceInputByIDToHTML(element.ID).then(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      }
    );
  }
  onPrintAll() {
    this.isShowLoading = true;
    this.DownloadService.InvoiceInputGetBySupplierIDAndDateTimeBeginAndDateTimeEndToHTMLAsync(this.supplierID, this.dateTimeBegin, this.dateTimeEnd).subscribe(
      res => {                   
        window.open(res.toString(), "_blank");  
        this.isShowLoading = false;       
      },
      err => {        
        console.log(err);
        this.isShowLoading = false;
      }
    );
  }
  onFilterSupplier(searchString: string) {
    if (searchString.length > 0) {
      this.SupplierService.listSearch = this.SupplierService.list.filter(item => item.Display && item.Display.length > 0 && item.Display.toLocaleLowerCase().includes(searchString));      
    }
    else {
      this.SupplierService.listSearch = this.SupplierService.list;
    }
  }
  onSyncByDateTimeBeginAndDateTimeEndAsync() {
    this.isShowLoading = true;
    this.InvoiceInputService.SyncByDateTimeBeginAndDateTimeEndAsync(this.dateTimeBegin, this.dateTimeEnd).subscribe(
      res => {
        this.NotificationService.warn(environment.SaveSuccess);
        this.onSearch();
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
}
