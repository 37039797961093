import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/notification.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { InvoiceOutput } from 'src/app/shared/InvoiceOutput.model';
import { InvoiceOutputService } from 'src/app/shared/InvoiceOutput.service';
import { InvoiceOutputDetailComponent } from './invoice-output-detail/invoice-output-detail.component';
import { DownloadService } from 'src/app/shared/Download.service';
import { Customer } from 'src/app/shared/Customer.model';
import { CustomerService } from 'src/app/shared/Customer.service';

@Component({
  selector: 'app-invoice-output',
  templateUrl: './invoice-output.component.html',
  styleUrls: ['./invoice-output.component.css']
})
export class InvoiceOutputComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  displayColumns: string[] = ['DateCreated', 'Code', 'Name', 'TotalBeforeTax', 'Save'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  dateTimeBegin: Date = new Date();
  dateTimeEnd: Date = new Date();
  total: number = environment.InitializationNumber;
  customerID: number = environment.InitializationNumber;
  id: any;
  constructor(
    public InvoiceOutputService: InvoiceOutputService,
    public CustomerService: CustomerService,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.GetCustomerToListAsync();
  }
  onChangeDateTimeBegin(value) {
    this.dateTimeBegin = new Date(value);
  }
  onChangeDateTimeEnd(value) {
    this.dateTimeEnd = new Date(value);
  }
  GetCustomerToListAsync() {
    this.CustomerService.GetAllAndEmptyToListAsync().subscribe(
      res => {
        this.CustomerService.list = (res as Customer[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.CustomerService.listSearch = this.CustomerService.list;
        this.onSearch();
      },
      err => {
      }
    );
  }
  GetToList() {
    this.isShowLoading = true;
    this.InvoiceOutputService.GetByCustomerIDAndDateTimeBeginAndDateTimeEndToListAsync(this.customerID, this.dateTimeBegin, this.dateTimeEnd).subscribe(
      res => {
        this.InvoiceOutputService.list = res as InvoiceOutput[];
        if (this.InvoiceOutputService.list) {
          this.dataSource = new MatTableDataSource(this.InvoiceOutputService.list.sort((a, b) => (a.DateCreated > b.DateCreated ? 1 : -1)));
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.total = environment.InitializationNumber;
          for (let i = 0; i < this.InvoiceOutputService.list.length; i++) {
            this.total = this.total + this.InvoiceOutputService.list[i].TotalBeforeTax;
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetToList();
    }
  }
  onAdd(ID: any) {
    this.InvoiceOutputService.GetByIDAsync(ID).subscribe(
      res => {
        this.InvoiceOutputService.formData = res as InvoiceOutput;
        console.log(this.InvoiceOutputService.formData);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(InvoiceOutputDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.GetToList();
        });
      },
      err => {
      }
    );
  }
  onDelete(element: InvoiceOutput) {
    if (confirm(environment.DeleteConfirm)) {
      this.InvoiceOutputService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
        }
      );
    }
  }
  onPrint(element: InvoiceOutput) {
    this.isShowLoading = true;
    this.DownloadService.InvoiceOutputByIDToHTML(element.ID).then(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      }
    );
  }
  onPrintAll() {
    this.isShowLoading = true;
    this.DownloadService.InvoiceOutputGetByCustomerIDAndDateTimeBeginAndDateTimeEndToHTMLAsync(this.customerID, this.dateTimeBegin, this.dateTimeEnd).subscribe(
      res => {                   
        window.open(res.toString(), "_blank");  
        this.isShowLoading = false;       
      },
      err => {        
        console.log(err);
        this.isShowLoading = false;
      }
    );
  }
  onFilterCustomer(searchString: string) {
    if (searchString.length > 0) {
      this.CustomerService.listSearch = this.CustomerService.list.filter(item => item.Display && item.Display.length > 0 && item.Display.toLocaleLowerCase().includes(searchString));
    }
    else {
      this.CustomerService.listSearch = this.CustomerService.list;
    }
  }
  onSyncByDateTimeBeginAndDateTimeEndAsync() {
    this.isShowLoading = true;
    this.InvoiceOutputService.SyncByDateTimeBeginAndDateTimeEndAsync(this.dateTimeBegin, this.dateTimeEnd).subscribe(
      res => {
        this.NotificationService.warn(environment.SaveSuccess);
        this.onSearch();
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
}