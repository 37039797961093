import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/notification.service';
import { InvoiceOutput } from 'src/app/shared/InvoiceOutput.model';
import { InvoiceOutputService } from 'src/app/shared/InvoiceOutput.service';
import { InvoiceOutputDetail } from 'src/app/shared/InvoiceOutputDetail.model';
import { InvoiceOutputDetailService } from 'src/app/shared/InvoiceOutputDetail.service';
import { Customer } from 'src/app/shared/Customer.model';
import { CustomerService } from 'src/app/shared/Customer.service';
import { Product } from 'src/app/shared/Product.model';
import { ProductService } from 'src/app/shared/Product.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DownloadService } from 'src/app/shared/Download.service';

@Component({
  selector: 'app-invoice-output-detail',
  templateUrl: './invoice-output-detail.component.html',
  styleUrls: ['./invoice-output-detail.component.css']
})
export class InvoiceOutputDetailComponent implements OnInit {

  ID: number = environment.InitializationNumber;
  isShowLoading: boolean = false;

  dataSource: MatTableDataSource<any>;
  displayColumns: string[] = ['ProductID', 'Quantity', 'Price', 'Total', 'Save'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public InvoiceOutputService: InvoiceOutputService,
    public InvoiceOutputDetailService: InvoiceOutputDetailService,
    public CustomerService: CustomerService,
    public ProductService: ProductService,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    public dialogRef: MatDialogRef<InvoiceOutputDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.ID = data["ID"] as number;
  }
  ngOnInit(): void {    
    this.GetCustomerToListAsync();
    this.GetProductToListAsync();    
    this.GetByParentIDAndEmptyToListAsync();
  }
  GetCustomerToListAsync() {
    this.CustomerService.GetByActiveToListAsync(true).subscribe(
      res => {
        this.CustomerService.list = (res as Customer[]).sort((a, b) => (a.Display > b.Display ? 1 : -1));
        this.CustomerService.listSearch = this.CustomerService.list;
        if (this.CustomerService.listSearch) {
          if (this.CustomerService.listSearch.length > 0) {
            if (this.InvoiceOutputService.formData.ID == 0) {
              this.InvoiceOutputService.formData.CustomerID = this.CustomerService.listSearch[0].ID;
            }
          }
        }
      },
      err => {
      }
    );
  }
  GetProductToListAsync() {
    this.ProductService.GetByActiveToListAsync(true).subscribe(
      res => {
        this.ProductService.list = (res as Product[]).sort((a, b) => (a.Display > b.Display ? 1 : -1));       
      },
      err => {
      }
    );
  }
  onClose() {
    this.dialogRef.close();
  }

  onSubmit(form: NgForm) {
    this.InvoiceOutputService.SaveAsync(form.value).subscribe(
      res => {
        this.InvoiceOutputService.formData = res as InvoiceOutput;
        this.GetByIDAsync();
        this.NotificationService.success(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onChangeDateCreated(value) {
    this.InvoiceOutputService.formData.DateCreated = new Date(value);
  }
  GetByIDAsync() {
    this.InvoiceOutputService.GetByIDAsync(this.InvoiceOutputService.formData.ID).subscribe(
      res => {
        this.InvoiceOutputService.formData = res as InvoiceOutput;     
        this.GetByParentIDAndEmptyToListAsync();  
      },
      err => {
      }
    );
  }
  GetByParentIDAndEmptyToListAsync() {
    this.isShowLoading = true;
    this.InvoiceOutputDetailService.GetByParentIDAndEmptyToListAsync(this.InvoiceOutputService.formData.ID).subscribe(
      res => {
        this.InvoiceOutputDetailService.list = res as InvoiceOutputDetail[];
        this.dataSource = new MatTableDataSource(this.InvoiceOutputDetailService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
        
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSave(element: InvoiceOutputDetail) {
    element.ParentID = this.InvoiceOutputService.formData.ID;
    this.InvoiceOutputDetailService.SaveAsync(element).subscribe(
      res => {
        this.GetByIDAsync();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onDelete(element: InvoiceOutputDetail) {
    if (confirm(environment.DeleteConfirm)) {
      this.InvoiceOutputDetailService.RemoveAsync(element.ID).subscribe(
        res => {
          this.GetByIDAsync();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  onPrint() {
    this.isShowLoading = true;
    this.DownloadService.InvoiceOutputByIDToHTML(this.InvoiceOutputService.formData.ID).then(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      }
    );
  }
  onFilterCustomer(searchString: string) {
    if (searchString.length > 0) {
      this.CustomerService.listSearch = this.CustomerService.list.filter(item => item.Display && item.Display.length > 0 && item.Display.toLocaleLowerCase().includes(searchString));
    }
    else {
      this.CustomerService.listSearch = this.CustomerService.list;
    }
  }
}