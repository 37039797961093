import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-membership-detail',
  templateUrl: './membership-detail.component.html',
  styleUrls: ['./membership-detail.component.css']
})
export class MembershipDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
