import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { CKEditorModule } from 'ngx-ckeditor';
import { NotificationService } from './shared/notification.service';
import { CookieService } from 'ngx-cookie-service';
import { ChartsModule } from 'ng2-charts';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { AppComponent } from './app.component';
import { LoadingComponent } from './loading/loading.component';
import { HomepageComponent } from './homepage/homepage.component';
import { CustomerComponent } from './customer/customer.component';
import { ProductComponent } from './product/product.component';
import { SupplierComponent } from './supplier/supplier.component';
import { ProductWarehouseComponent } from './product-warehouse/product-warehouse.component';
import { InvoiceInputComponent } from './invoice-input/invoice-input.component';
import { InvoiceOutputComponent } from './invoice-output/invoice-output.component';
import { InvoiceInputDetailComponent } from './invoice-input/invoice-input-detail/invoice-input-detail.component';
import { InvoiceOutputDetailComponent } from './invoice-output/invoice-output-detail/invoice-output-detail.component';
import { InvoiceInputDetailHistoryComponent } from './invoice-input/invoice-input-detail-history/invoice-input-detail-history.component';
import { InvoiceOutputDetailHistoryComponent } from './invoice-output/invoice-output-detail-history/invoice-output-detail-history.component';
import { MembershipComponent } from './membership/membership.component';
import { InvoiceCostComponent } from './invoice-cost/invoice-cost.component';
import { InvoiceCostDetailComponent } from './invoice-cost/invoice-cost-detail/invoice-cost-detail.component';
import { InvoiceSalaryComponent } from './invoice-salary/invoice-salary.component';
import { InvoiceSalaryDetailComponent } from './invoice-salary/invoice-salary-detail/invoice-salary-detail.component';
import { InvoiceCostDetailHistoryComponent } from './invoice-cost/invoice-cost-detail-history/invoice-cost-detail-history.component';
import { InvoiceSalaryDetailHistoryComponent } from './invoice-salary/invoice-salary-detail-history/invoice-salary-detail-history.component';
import { MembershipDetailComponent } from './membership/membership-detail/membership-detail.component';
import { ReportComponent } from './report/report.component';


@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    HomepageComponent,
    CustomerComponent,
    ProductComponent,
    SupplierComponent,
    ProductWarehouseComponent,
    InvoiceInputComponent,
    InvoiceOutputComponent,
    InvoiceInputDetailComponent,
    InvoiceOutputDetailComponent,
    InvoiceInputDetailHistoryComponent,
    InvoiceOutputDetailHistoryComponent,
    MembershipComponent,
    InvoiceCostComponent,
    InvoiceCostDetailComponent,
    InvoiceSalaryComponent,    
    InvoiceSalaryDetailComponent,
    InvoiceCostDetailHistoryComponent,
    InvoiceSalaryDetailHistoryComponent,
    MembershipDetailComponent,
    ReportComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ChartsModule,
    CKEditorModule,
  ],
  providers: [   
    CookieService,  
    NotificationService,
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'}
  ],
  bootstrap: [AppComponent],
  entryComponents:[]
})
export class AppModule { }
