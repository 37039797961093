import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/notification.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { InvoiceCost } from 'src/app/shared/InvoiceCost.model';
import { InvoiceCostService } from 'src/app/shared/InvoiceCost.service';
import { Supplier } from 'src/app/shared/Supplier.model';
import { SupplierService } from 'src/app/shared/Supplier.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { InvoiceCostDetailComponent } from './invoice-cost-detail/invoice-cost-detail.component';

@Component({
  selector: 'app-invoice-cost',
  templateUrl: './invoice-cost.component.html',
  styleUrls: ['./invoice-cost.component.css']
})
export class InvoiceCostComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  displayColumns: string[] = ['DateCreated', 'TotalBeforeTax', 'Save'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  dateTimeBegin: Date = new Date();
  dateTimeEnd: Date = new Date();
  total: number = environment.InitializationNumber;
  supplierID: number = environment.InitializationNumber;
  id: any;
  constructor(
    public InvoiceCostService: InvoiceCostService,
    public SupplierService: SupplierService,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {    
    this.onSearch();
  }
  onChangeDateTimeBegin(value) {
    this.dateTimeBegin = new Date(value);
  }
  onChangeDateTimeEnd(value) {
    this.dateTimeEnd = new Date(value);
  }  
  GetToList() {
    this.isShowLoading = true;
    this.InvoiceCostService.GetByDateTimeBeginAndDateTimeEndAndSearchStringToListAsync(this.dateTimeBegin, this.dateTimeEnd, this.searchString).subscribe(
      res => {
        this.InvoiceCostService.list = res as InvoiceCost[];
        if (this.InvoiceCostService.list) {
          this.dataSource = new MatTableDataSource(this.InvoiceCostService.list.sort((a, b) => (a.DateCreated > b.DateCreated ? 1 : -1)));
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.total = environment.InitializationNumber;
          for (let i = 0; i < this.InvoiceCostService.list.length; i++) {
            this.total = this.total + this.InvoiceCostService.list[i].TotalBeforeTax;
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSyncByDateTimeBeginAndDateTimeEndAsync() {
    this.isShowLoading = true;
    this.InvoiceCostService.SyncByDateTimeBeginAndDateTimeEndAsync(this.dateTimeBegin, this.dateTimeEnd).subscribe(
      res => {
        this.NotificationService.warn(environment.SaveSuccess);        
        this.onSearch();
        this.isShowLoading = false;        
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetToList();
    }
  }
  onAdd(ID: any) {
    this.InvoiceCostService.GetByIDAsync(ID).subscribe(
      res => {
        this.InvoiceCostService.formData = res as InvoiceCost;
        console.log(this.InvoiceCostService.formData);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(InvoiceCostDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.GetToList();
        });
      },
      err => {
      }
    );
  }
  onDelete(element: InvoiceCost) {
    if (confirm(environment.DeleteConfirm)) {
      this.InvoiceCostService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
        }
      );
    }
  }
}