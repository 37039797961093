import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/notification.service';
import { InvoiceCost } from 'src/app/shared/InvoiceCost.model';
import { InvoiceCostService } from 'src/app/shared/InvoiceCost.service';
import { InvoiceCostDetail } from 'src/app/shared/InvoiceCostDetail.model';
import { InvoiceCostDetailService } from 'src/app/shared/InvoiceCostDetail.service';
import { Supplier } from 'src/app/shared/Supplier.model';
import { SupplierService } from 'src/app/shared/Supplier.service';
import { Membership } from 'src/app/shared/Membership.model';
import { MembershipService } from 'src/app/shared/Membership.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DownloadService } from 'src/app/shared/Download.service';

@Component({
  selector: 'app-invoice-cost-detail',
  templateUrl: './invoice-cost-detail.component.html',
  styleUrls: ['./invoice-cost-detail.component.css']
})
export class InvoiceCostDetailComponent implements OnInit {

  ID: number = environment.InitializationNumber;
  isShowLoading: boolean = false;

  dataSource: MatTableDataSource<any>;
  displayColumns: string[] = ['Note', 'Quantity', 'Price', 'Total', 'Save'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public InvoiceCostService: InvoiceCostService,
    public InvoiceCostDetailService: InvoiceCostDetailService,
    public SupplierService: SupplierService,
    public MembershipService: MembershipService,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    public dialogRef: MatDialogRef<InvoiceCostDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.ID = data["ID"] as number;
  }
  ngOnInit(): void {            
    this.GetByParentIDAndEmptyToListAsync();
  }    
  onClose() {
    this.dialogRef.close();
  }

  onSubmit(form: NgForm) {
    this.InvoiceCostService.SaveAsync(form.value).subscribe(
      res => {
        this.InvoiceCostService.formData = res as InvoiceCost;
        this.GetByIDAsync();
        this.NotificationService.success(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onChangeDateCreated(value) {
    this.InvoiceCostService.formData.DateCreated = new Date(value);
  }
  GetByIDAsync() {
    this.InvoiceCostService.GetByIDAsync(this.InvoiceCostService.formData.ID).subscribe(
      res => {
        this.InvoiceCostService.formData = res as InvoiceCost;     
        this.GetByParentIDAndEmptyToListAsync();  
      },
      err => {
      }
    );
  }
  GetByParentIDAndEmptyToListAsync() {
    this.isShowLoading = true;
    this.InvoiceCostDetailService.GetByParentIDAndEmptyToListAsync(this.InvoiceCostService.formData.ID).subscribe(
      res => {
        this.InvoiceCostDetailService.list = res as InvoiceCostDetail[];
        this.dataSource = new MatTableDataSource(this.InvoiceCostDetailService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;        
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSave(element: InvoiceCostDetail) {
    element.ParentID = this.InvoiceCostService.formData.ID;
    this.InvoiceCostDetailService.SaveAsync(element).subscribe(
      res => {
        this.GetByIDAsync();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onDelete(element: InvoiceCostDetail) {
    if (confirm(environment.DeleteConfirm)) {
      this.InvoiceCostDetailService.RemoveAsync(element.ID).subscribe(
        res => {
          this.GetByIDAsync();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  } 
}