import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/notification.service';
import { InvoiceInput } from 'src/app/shared/InvoiceInput.model';
import { InvoiceInputService } from 'src/app/shared/InvoiceInput.service';
import { InvoiceInputDetail } from 'src/app/shared/InvoiceInputDetail.model';
import { InvoiceInputDetailService } from 'src/app/shared/InvoiceInputDetail.service';
import { Supplier } from 'src/app/shared/Supplier.model';
import { SupplierService } from 'src/app/shared/Supplier.service';
import { Product } from 'src/app/shared/Product.model';
import { ProductService } from 'src/app/shared/Product.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DownloadService } from 'src/app/shared/Download.service';

@Component({
  selector: 'app-invoice-input-detail-history',
  templateUrl: './invoice-input-detail-history.component.html',
  styleUrls: ['./invoice-input-detail-history.component.css']
})
export class InvoiceInputDetailHistoryComponent implements OnInit {

  ID: number = environment.InitializationNumber;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  dateTimeBegin: Date = new Date();
  dateTimeEnd: Date = new Date();
  isDateCreated: boolean = true;
  total: number = environment.InitializationNumber;
  supplierID: number = environment.InitializationNumber;
  dataSource: MatTableDataSource<any>;
  displayColumns: string[] = ['DateCreated', 'SupplierID', 'ProductID', 'Quantity', 'Price', 'Note', 'Total', 'Save'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public InvoiceInputService: InvoiceInputService,
    public InvoiceInputDetailService: InvoiceInputDetailService,
    public SupplierService: SupplierService,
    public ProductService: ProductService,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
  ) {

  }
  ngOnInit(): void {
    this.GetSupplierToListAsync();
    this.GetProductToListAsync();
    this.GetToList();
  }
  onChangeDateTimeBegin(value) {
    this.dateTimeBegin = new Date(value);
  }
  onChangeDateTimeEnd(value) {
    this.dateTimeEnd = new Date(value);
  }
  onSearch() {
    //this.GetSupplierToListAsync();
    this.GetProductToListAsync();
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetToList();
    }
  }
  GetSupplierToListAsync() {
    this.SupplierService.GetAllAndEmptyToListAsync().subscribe(
      res => {
        this.SupplierService.list = (res as Supplier[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.SupplierService.listSearch = this.SupplierService.list;
        this.SupplierService.listSearch001 = this.SupplierService.list;
      },
      err => {
      }
    );
  }
  GetProductToListAsync() {
    this.ProductService.GetByActiveToListAsync(true).subscribe(
      res => {
        this.ProductService.list = (res as Product[]).sort((a, b) => (a.Display > b.Display ? 1 : -1));
      },
      err => {
      }
    );
  }
  GetToList() {
    this.isShowLoading = true;
    this.InvoiceInputDetailService.GetBySupplierIDAndDateTimeBeginAndDateTimeEndAndIsDateCreatedToListAsync(this.supplierID, this.dateTimeBegin, this.dateTimeEnd, this.isDateCreated).subscribe(
      res => {
        this.InvoiceInputDetailService.list = res as InvoiceInput[];
        if (this.InvoiceInputDetailService.list) {
          this.dataSource = new MatTableDataSource(this.InvoiceInputDetailService.list.sort((a, b) => (a.SupplierID > b.SupplierID ? 1 : -1)));
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.total = environment.InitializationNumber;
          for (let i = 0; i < this.InvoiceInputDetailService.list.length; i++) {
            this.total = this.total + this.InvoiceInputDetailService.list[i].Total;
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onChangeDateCreated(element: InvoiceInputDetail, value) {
    element.DateCreated = new Date(value);
  }
  onFilterSupplier(searchString: string) {
    if (searchString.length > 0) {
      this.SupplierService.listSearch = this.SupplierService.list.filter(item => item.Display && item.Display.length > 0 && item.Display.toLocaleLowerCase().includes(searchString));      
    }
    else {
      this.SupplierService.listSearch = this.SupplierService.list;
    }
  }
  onFilterSupplier001(searchString: string) {
    if (searchString.length > 0) {
      this.SupplierService.listSearch001 = this.SupplierService.list.filter(item => item.Display && item.Display.length > 0 && item.Display.toLocaleLowerCase().includes(searchString));      
    }
    else {
      this.SupplierService.listSearch001 = this.SupplierService.list;
    }
  }
  onSave(element: InvoiceInputDetail) {
    console.log(element);
    this.InvoiceInputDetailService.SaveAsync(element).subscribe(
      res => {
        this.GetToList();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onDelete(element: InvoiceInputDetail) {
    if (confirm(environment.DeleteConfirm)) {
      this.InvoiceInputDetailService.RemoveAsync(element.ID).subscribe(
        res => {
          this.GetToList();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  onPrint(element: InvoiceInputDetail) {
    this.isShowLoading = true;
    this.DownloadService.InvoiceInputByIDToHTML(element.ParentID).then(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      }
    );
  }
  onPrintAll() {
    this.isShowLoading = true;
    this.DownloadService.InvoiceInputGetBySupplierIDAndDateTimeBeginAndDateTimeEndToHTMLAsync(this.supplierID, this.dateTimeBegin, this.dateTimeEnd).subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        console.log(err);
        this.isShowLoading = false;
      }
    );
  }
  onSyncByDateTimeBeginAndDateTimeEndAsync() {
    this.isShowLoading = true;
    this.InvoiceInputService.SyncByDateTimeBeginAndDateTimeEndAsync(this.dateTimeBegin, this.dateTimeEnd).subscribe(
      res => {
        this.NotificationService.warn(environment.SaveSuccess);
        this.onSearch();
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
}